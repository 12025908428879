var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./metabase.lib.cache.js");require("./metabase.util.js");require("./metabase.lib.util.js");require("./metabase.util.log.js");
'use strict';var E9,jya,mya,nya,oya,pya,qya,rya,tya,J9,uya,vya,kya,M9,wya,O9,xya,yya,zya;$CLJS.D9=function(a,b){return $CLJS.n($CLJS.n(a)?b in a:a)?$CLJS.Aa(a,b):null};E9=function(a,b){return jya(a,b,$CLJS.N)};
jya=function(a,b,c){c=$CLJS.O(c);c=$CLJS.K.j(c,kya,!0);if($CLJS.td(b))return $CLJS.Wf.j($CLJS.N,a,b);c=$CLJS.n(c)?function(){var d=$CLJS.D9(b,"_plainObject");d=null==d?null:$CLJS.My(d);return null==d?null:$CLJS.Be(d)}():null;return $CLJS.n(c)?$CLJS.Wf.j($CLJS.N,a,c):$CLJS.Wf.j($CLJS.N,$CLJS.ik.j($CLJS.cf.h(function(d){return new $CLJS.P(null,2,5,$CLJS.Q,[d,$CLJS.D9(b,d)],null)}),$CLJS.jk.h(function(d){$CLJS.I(d,0,null);return"function"===typeof $CLJS.I(d,1,null)}),a),$CLJS.za(b))};
mya=function(a){var b=F9.h(a),c=G9.h(a),d=H9.h(a);return $CLJS.ik.j($CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);var k=$CLJS.Q;f=$CLJS.mh.h(lya(f));if($CLJS.n(d)){var l=d.h?d.h(f):d.call(null,f);f=$CLJS.n(l)?l:f}return new $CLJS.P(null,2,5,k,[f,e],null)}),$CLJS.pd(b)?$CLJS.Pd:$CLJS.jk.h(function(e){var f=$CLJS.I(e,0,null);$CLJS.I(e,1,null);return $CLJS.Dd(b,f)}),$CLJS.Ra(c)?$CLJS.Pd:$CLJS.cf.h(function(e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return new $CLJS.P(null,2,
5,$CLJS.Q,[f,c.g?c.g(f,e):c.call(null,f,e)],null)}))};nya=function(a){return E9($CLJS.cf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);c=$CLJS.mh.h(lya(c));c=$CLJS.E.g(c,$CLJS.CU)?$CLJS.CD:c;b=$CLJS.E.g(c,$CLJS.CD)?$CLJS.mh.h(b):b;return new $CLJS.P(null,2,5,$CLJS.Q,[c,b],null)}),a)};
oya=function(a){var b=$CLJS.tG(function(d){d=$CLJS.D9(d,"type");var e=new $CLJS.Rg(null,new $CLJS.h(null,2,["internal",null,"external",null],null),null);return e.h?e.h(d):e.call(null,d)},a);if($CLJS.n(b)){var c=$CLJS.mh.h($CLJS.D9(b,"type"));return $CLJS.Ak.l($CLJS.H([new $CLJS.h(null,2,[$CLJS.Ii,$CLJS.D9(b,"id"),$CLJS.T,$CLJS.D9(b,"name")],null),function(){var d=c instanceof $CLJS.M?c.S:null;switch(d){case "external":return new $CLJS.h(null,2,[$CLJS.gj,$CLJS.lK,$CLJS.XJ,$CLJS.D9(b,"human_readable_field_id")],
null);case "internal":return new $CLJS.h(null,1,[$CLJS.gj,$CLJS.gK],null);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}()]))}return null};pya=function(a){return $CLJS.kk.g(I9.g($CLJS.kF,$CLJS.N),a)};
qya=function(a,b){var c=I9.g($CLJS.wM,new $CLJS.h(null,1,[kya,!1],null)),d=I9.g($CLJS.wM,$CLJS.N);return $CLJS.Ak.l($CLJS.H([$CLJS.R.j(function(){var e=$CLJS.D9($CLJS.D9(a,"tables"),["card__",$CLJS.p.h(b)].join(""));return c.h?c.h(e):c.call(null,e)}(),$CLJS.Ii,b),function(){var e=$CLJS.D9($CLJS.D9(a,"questions"),$CLJS.p.h(b));var f=$CLJS.D9(e,"_card");e=$CLJS.n(f)?f:e;return d.h?d.h(e):d.call(null,e)}()]))};rya=new $CLJS.M(null,"plain-object","plain-object",-943264281);
$CLJS.sya=new $CLJS.M(null,"cards","cards",169174038);tya=new $CLJS.M(null,"binning-info","binning-info",-2117036653);J9=new $CLJS.M("metabase.lib.js.metadata","field-values","metabase.lib.js.metadata/field-values",-88384962);uya=new $CLJS.M(null,"db","db",993250759);$CLJS.K9=new $CLJS.M(null,"metrics","metrics",394093469);vya=new $CLJS.M(null,"fks","fks",2041987076);$CLJS.L9=new $CLJS.M(null,"segments","segments",1937535949);kya=new $CLJS.M(null,"use-plain-object?","use-plain-object?",-1174595475);
M9=new $CLJS.M("metabase.lib.js.metadata","dimension","metabase.lib.js.metadata/dimension",2004773191);wya=new $CLJS.M(null,"metadata","metadata",1799301597);$CLJS.N9=new $CLJS.M(null,"tables","tables",1334623052);O9=new $CLJS.M(null,"dimension-options","dimension-options",2122379680);xya=new $CLJS.M(null,"schema-name","schema-name",1666725119);yya=new $CLJS.M(null,"_comesFromEndpoint","_comesFromEndpoint",-1390455588);zya=new $CLJS.M(null,"default-dimension-option","default-dimension-option",149055958);
$CLJS.Aya=new $CLJS.M(null,"databases","databases",2040134125);var lya=$CLJS.Ah($CLJS.eA),F9,Bya=$CLJS.Se($CLJS.N),Cya=$CLJS.Se($CLJS.N),Dya=$CLJS.Se($CLJS.N),Eya=$CLJS.Se($CLJS.N),Fya=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null)),R9,Vya,Wya,Xya,Yya,Zya;F9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","excluded-keys"),$CLJS.mh,Fya,Bya,Cya,Dya,Eya);F9.m(null,$CLJS.Oh,function(){return null});
var G9,Gya=$CLJS.Se($CLJS.N),Hya=$CLJS.Se($CLJS.N),Iya=$CLJS.Se($CLJS.N),Jya=$CLJS.Se($CLJS.N),Kya=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));G9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-field-fn"),$CLJS.mh,Kya,Gya,Hya,Iya,Jya);G9.m(null,$CLJS.Oh,function(){return null});var P9,Lya=$CLJS.Se($CLJS.N),Mya=$CLJS.Se($CLJS.N),Nya=$CLJS.Se($CLJS.N),Oya=$CLJS.Se($CLJS.N),Pya=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));
P9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","lib-type"),$CLJS.mh,Pya,Lya,Mya,Nya,Oya);var H9,Qya=$CLJS.Se($CLJS.N),Rya=$CLJS.Se($CLJS.N),Sya=$CLJS.Se($CLJS.N),Tya=$CLJS.Se($CLJS.N),Uya=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));H9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","rename-key-fn"),$CLJS.Pd,Uya,Qya,Rya,Sya,Tya);H9.m(null,$CLJS.Oh,function(){return null});
var I9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-object-fn*"),function(f){return f},e,a,b,c,d)}();
I9.m(null,$CLJS.Oh,function(a,b){var c=mya(a),d=P9.h(a);return function(e){try{var f=$CLJS.R.j(jya(c,e,b),$CLJS.gj,d),k=$CLJS.Mz($CLJS.Bx);$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",k))&&$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.uV("Parsed metadata %s %s\n%s",$CLJS.H([a,$CLJS.Ii.h(f),$CLJS.vV(f)])),null);return f}catch(l){if(l instanceof Error)return f=l,k=$CLJS.Mz($CLJS.Cx),$CLJS.n($CLJS.Lz("metabase.lib.js.metadata",k))&&(f instanceof Error?$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.uV("Error parsing %s %s: %s",
$CLJS.H([a,$CLJS.xh.l($CLJS.H([e])),$CLJS.tU(f)])),f):$CLJS.Kz("metabase.lib.js.metadata",k,$CLJS.uV(f,$CLJS.H(["Error parsing %s %s: %s",a,$CLJS.xh.l($CLJS.H([e])),$CLJS.tU(f)])),null)),null;throw l;}}});$CLJS.Q9=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects"),function(f){return $CLJS.mh.h(f)},e,a,b,c,d)}();
Vya=$CLJS.Se($CLJS.N);Wya=$CLJS.Se($CLJS.N);Xya=$CLJS.Se($CLJS.N);Yya=$CLJS.Se($CLJS.N);Zya=$CLJS.K.j($CLJS.N,$CLJS.Qi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));R9=new $CLJS.Ph($CLJS.qh.g("metabase.lib.js.metadata","parse-objects-default-key"),$CLJS.mh,Zya,Vya,Wya,Xya,Yya);
$CLJS.Q9.m(null,$CLJS.Oh,function(a,b){var c=I9.g(a,$CLJS.N);return E9($CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)}),$CLJS.D9(b,R9.h(a)))});P9.m(null,$CLJS.VO,function(){return $CLJS.iK});F9.m(null,$CLJS.VO,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.SD,null,$CLJS.N9,null],null),null)});
G9.m(null,$CLJS.VO,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "dbms-version":return $CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]));case "features":return $CLJS.Wf.j($CLJS.Sg,$CLJS.cf.h($CLJS.mh),b);case "native-permissions":return $CLJS.mh.h(b);default:return b}}});R9.m(null,$CLJS.VO,function(){return"databases"});P9.m(null,$CLJS.Mj,function(){return $CLJS.UJ});
F9.m(null,$CLJS.Mj,function(){return new $CLJS.Rg(null,new $CLJS.h(null,5,[O9,null,$CLJS.SD,null,$CLJS.L9,null,$CLJS.VO,null,$CLJS.K9,null],null),null)});G9.m(null,$CLJS.Mj,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "entity-type":return $CLJS.mh.h(b);case "field-order":return $CLJS.mh.h(b);case "initial-sync-status":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);default:return b}}});
$CLJS.Q9.m(null,$CLJS.Mj,function(a,b){var c=I9.g(a,$CLJS.N);return E9($CLJS.ik.g($CLJS.jk.h(function(d){var e=$CLJS.I(d,0,null);$CLJS.I(d,1,null);return 0==e.lastIndexOf("card__",0)}),$CLJS.cf.h(function(d){var e=$CLJS.I(d,0,null),f=$CLJS.I(d,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tV(e),new $CLJS.uh(function(){return c.h?c.h(f):c.call(null,f)})],null)})),$CLJS.D9(b,"tables"))});P9.m(null,$CLJS.kF,function(){return $CLJS.MJ});
F9.m(null,$CLJS.kF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,6,[O9,null,$CLJS.Mj,null,zya,null,$CLJS.VO,null,yya,null,$CLJS.K9,null],null),null)});H9.m(null,$CLJS.kF,function(){return new $CLJS.h(null,6,[$CLJS.Yy,$CLJS.OJ,$CLJS.di,$CLJS.JV,$CLJS.$Q,$CLJS.oD,tya,$CLJS.OV,$CLJS.KK,M9,$CLJS.Tr,J9],null)});
G9.m(null,$CLJS.kF,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "base-type":return $CLJS.mh.h(b);case "coercion-strategy":return $CLJS.mh.h(b);case "effective-type":return $CLJS.mh.h(b);case "fingerprint":return $CLJS.td(b)?$CLJS.vU(b):$CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]));case "has-field-values":return $CLJS.mh.h(b);case "field-ref":return $CLJS.re(b);case "lib/source":switch(b){case "aggregation":return $CLJS.mK;case "breakout":return $CLJS.eK;default:return $CLJS.mh.g("source",
b)}case "metabase.lib.field/temporal-unit":return $CLJS.mh.h(b);case "semantic-type":return $CLJS.mh.h(b);case "visibility-type":return $CLJS.mh.h(b);case "id":return $CLJS.vd(b)?$CLJS.dd(b):b;case "metabase.lib.field/binning":return nya(b);case "metabase.lib.js.metadata/field-values":return $CLJS.E.g($CLJS.D9(b,"type"),"full")?new $CLJS.h(null,2,[$CLJS.Tr,$CLJS.My($CLJS.D9(b,"values")),$CLJS.TJ,$CLJS.My($CLJS.D9(b,"human_readable_values"))],null):null;case "metabase.lib.js.metadata/dimension":return oya(b);
default:return b}}});
I9.m(null,$CLJS.kF,function(a,b){var c=function(){var d=$CLJS.mH(I9,$CLJS.Oh);return d.g?d.g(a,b):d.call(null,a,b)}();return function(d){d=c.h?c.h(d):c.call(null,d);var e=$CLJS.O(d);d=$CLJS.K.g(e,M9);var f=$CLJS.O(d),k=$CLJS.K.g(f,$CLJS.gj),l=$CLJS.K.g(e,J9);return $CLJS.bk.l(function(){switch(k instanceof $CLJS.M?k.S:null){case "metadata.column.remapping/external":return $CLJS.R.j(e,$CLJS.aK,f);case "metadata.column.remapping/internal":return $CLJS.R.j(e,$CLJS.hK,$CLJS.Ak.l($CLJS.H([f,l])));default:return e}}(),
M9,$CLJS.H([J9]))}});
$CLJS.Q9.m(null,$CLJS.kF,function(a,b){var c=I9.g(a,$CLJS.N),d=$CLJS.D9(b,"fields");return E9($CLJS.ul(function(e){var f=$CLJS.I(e,0,null),k=$CLJS.I(e,1,null);e=function(){var l=$CLJS.tV(f);if($CLJS.n(l))return l;l=$CLJS.dh(/card__\d+:(\d+)/,f);if($CLJS.n(l)){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);var m=null==$CLJS.D9(d,l);return m?$CLJS.tV(l):m}return null}();return $CLJS.n(e)?new $CLJS.P(null,2,5,$CLJS.Q,[e,new $CLJS.uh(function(){return c.h?c.h(k):c.call(null,k)})],null):null}),d)});
P9.m(null,$CLJS.wM,function(){return $CLJS.kK});F9.m(null,$CLJS.wM,function(){return new $CLJS.Rg(null,new $CLJS.h(null,11,[O9,null,$CLJS.Pk,null,vya,null,$CLJS.Mj,null,uya,null,rya,null,$CLJS.L9,null,$CLJS.VO,null,wya,null,$CLJS.K9,null,xya,null],null),null)});
G9.m(null,$CLJS.wM,function(){return function(a,b){switch(a instanceof $CLJS.M?a.S:null){case "result-metadata":return $CLJS.n($CLJS.zz($CLJS.sd,$CLJS.Pa)(b))?pya(b):$CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]));case "fields":return pya(b);case "visibility-type":return $CLJS.mh.h(b);case "dataset-query":return $CLJS.Ly(b,$CLJS.H([$CLJS.ji,!0]));case "dataset":return b;default:return b}}});
$CLJS.Q9.m(null,$CLJS.wM,function(a,b){return $CLJS.Wf.j($CLJS.N,$CLJS.cf.h(function(c){return new $CLJS.P(null,2,5,$CLJS.Q,[c,new $CLJS.uh(function(){return qya(b,c)})],null)}),$CLJS.Wf.j($CLJS.Wf.j($CLJS.Sg,$CLJS.ul($CLJS.GV),$CLJS.za($CLJS.D9(b,"tables"))),$CLJS.cf.h($CLJS.tV),$CLJS.za($CLJS.D9(b,"questions"))))});P9.m(null,$CLJS.fF,function(){return $CLJS.SJ});F9.m(null,$CLJS.fF,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Mj,null,$CLJS.VO,null],null),null)});
G9.m(null,$CLJS.fF,function(){return function(a,b){return b}});R9.m(null,$CLJS.fF,function(){return"metrics"});P9.m(null,$CLJS.IE,function(){return $CLJS.QJ});F9.m(null,$CLJS.IE,function(){return new $CLJS.Rg(null,new $CLJS.h(null,2,[$CLJS.Mj,null,$CLJS.VO,null],null),null)});G9.m(null,$CLJS.IE,function(){return function(a,b){return b}});R9.m(null,$CLJS.IE,function(){return"segments"});$CLJS.S9=I9.g($CLJS.kF,$CLJS.N);